import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule as PrimeNGSharedModule } from 'primeng/api';
import { StepsComponent } from './sb-steps.component';
import { StepsModule } from 'primeng/steps';
import { IconComponent } from '../../../+authenticated/shared/icon.component';

const components = [StepsComponent];
@NgModule({
  imports: [CommonModule, IconComponent, PrimeNGSharedModule, StepsModule],
  declarations: components,
  exports: components,
})
export class SbStepsModule {
  public static forRoot(): ModuleWithProviders<SbStepsModule> {
    return {
      ngModule: SbStepsModule,
    };
  }
}
