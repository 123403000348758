import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'sb-steps',
  templateUrl: './sb-steps.component.html',
  styleUrls: ['sb-steps.component.scss'],
})
export class StepsComponent {
  @Output()
  public stepChanged = new EventEmitter<number>();

  @Input()
  public items: MenuItem[];

  @Input()
  public activeIndex: number;

  @Input()
  public readOnly: boolean;

  public stepperChanged(stepIndex: number): void {
    this.stepChanged.emit(stepIndex);
  }
}
